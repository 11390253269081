import React from "react"
import { Menu as AntMenu } from "antd"
import { Link, navigate } from "gatsby"
import Logo from "./../../static/img/logo_black.png"
import { useLocation } from "@reach/router"

const Menu = () => {
  const { SubMenu } = AntMenu
  const location = useLocation()
  return (
    <div className="flex justify-between items-center p-5 px-20 menu-fixed">
      <div className="">
          <img src={Logo} width="200px" className="menu-logo" alt="logo" />
      </div>
      <div>
        <div className="lg:hidden">
          <AntMenu mode="horizontal">
            <SubMenu
              className="link-black"
              key="SubMenu"
              title={<i className="fas fa-bars"></i>}
            >
              <AntMenu.Item key="pricing" className="link-black">
              <span
                onClick={() => {
                  return document.getElementById("pricing")
                    ? document.getElementById("pricing").scrollIntoView()
                    : navigate("/")
                }}
                onKeyDown={() => {
                  return document.getElementById("pricing")
                    ? document.getElementById("pricing").scrollIntoView()
                    : navigate("/")
                }}
                role="button"
                tabIndex={0}
              >
                Pricing
              </span>
              </AntMenu.Item>
              {/* <AntMenu.Item key="youtube">
                <Link to="/youtube/views">YouTube</Link>
              </AntMenu.Item> */}
              <AntMenu.Item key="features" className="link-black">
              <span
                onClick={() => {
                  return document.getElementById("features")
                    ? document.getElementById("features").scrollIntoView()
                    : navigate("/")
                }}
                onKeyDown={() => {
                  return document.getElementById("features")
                    ? document.getElementById("features").scrollIntoView()
                    : navigate("/")
                }}
                role="button"
                tabIndex={0}
              >
                Features
              </span>
              </AntMenu.Item>
              {location.pathname !== "/" && (
                <AntMenu.Item key="app" className="link-black">
                <span
                  onClick={() => {
                    return document.getElementById("faq")
                      ? document.getElementById("faq").scrollIntoView()
                      : navigate("/")
                  }}
                  onKeyDown={() => {
                    return document.getElementById("faq")
                      ? document.getElementById("faq").scrollIntoView()
                      : navigate("/")
                  }}
                  role="button"
                  tabIndex={0}
                >
                  FAQ
                </span>
                </AntMenu.Item>
              )}
              <AntMenu.Item key="contact">
                <Link to="/contact">Contact</Link>
              </AntMenu.Item>
            </SubMenu>
          </AntMenu>
        </div>
        <div className="hidden lg:inline">
          <AntMenu mode="horizontal">
            {/*<SubMenu className="link-black" key="SubMenuTikTok" title="TikTok">*/}
            {/*  <AntMenu.ItemGroup title="TikTok">*/}
            {/*    <AntMenu.Item key="tiktok:followers">*/}
            {/*      <Link to="/tiktok/followers">Followers</Link>*/}
            {/*    </AntMenu.Item>*/}
            {/*    <AntMenu.Item key="tiktok:views">*/}
            {/*      <Link to="/tiktok/views">Views</Link>*/}
            {/*    </AntMenu.Item>*/}
            {/*    <AntMenu.Item key="tiktok:likes">*/}
            {/*      <Link to="/tiktok/likes">Likes</Link>*/}
            {/*    </AntMenu.Item>*/}
            {/*  </AntMenu.ItemGroup>*/}
            {/*</SubMenu>*/}
            <AntMenu.Item key="pricing" className="link-black">
              <span
                onClick={() => {
                  return document.getElementById("pricing")
                    ? document.getElementById("pricing").scrollIntoView()
                    : navigate("/")
                }}
                onKeyDown={() => {
                  return document.getElementById("pricing")
                    ? document.getElementById("pricing").scrollIntoView()
                    : navigate("/")
                }}
                role="button"
                tabIndex={0}
              >
                Pricing
              </span>
            </AntMenu.Item>
            <AntMenu.Item key="features" className="link-black">
              <span
                onClick={() => {
                  return document.getElementById("features")
                    ? document.getElementById("features").scrollIntoView()
                    : navigate("/")
                }}
                onKeyDown={() => {
                  return document.getElementById("features")
                    ? document.getElementById("features").scrollIntoView()
                    : navigate("/")
                }}
                role="button"
                tabIndex={0}
              >
                Features
              </span>
            </AntMenu.Item>
            {location.pathname !== "/" && location.pathname !== "/tiktok-agency" && (
              <AntMenu.Item key="app" className="link-black">
                <span
                  onClick={() => {
                    return document.getElementById("faq")
                      ? document.getElementById("faq").scrollIntoView()
                      : navigate("/")
                  }}
                  onKeyDown={() => {
                    return document.getElementById("faq")
                      ? document.getElementById("faq").scrollIntoView()
                      : navigate("/")
                  }}
                  role="button"
                  tabIndex={0}
                >
                  FAQ
                </span>
              </AntMenu.Item>
            )}
            <AntMenu.Item key="contact">
              <Link className="link-black" to="/contact">
                Contact
              </Link>
            </AntMenu.Item>
          </AntMenu>
        </div>
      </div>
    </div>
  )
}

export default Menu
